<template>
  <div class="project-popup">
    <div class="project-wrapper" v-for="(project, index) in activeProjects" :key="index">
      <div class="background-img" :style="{ backgroundImage: `url(&quot;${project.image}&quot;)` }"></div>
      <div class="proj-content">
        <div class="proj-head">
          <div class="back-button"><a @click="goBack()">&larr; go back</a></div>
          <h1>{{ project.title }}</h1>
        </div>
        <div class="sub-content">

          <a :href="`${project.video}`" target="_blank" class="placeholder">
            <iframe width="560" height="315" :src="`${project.video}`" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
            <p>&#9654; Video lädt hier!</p>
          </a>



        <table class="rolls">
          <tr class="roll" v-for="(roll, index) in project.rolls" :key="index">
            <td class="roll-title">
              <b>{{ roll.title }}</b>
            </td>
            <td v-html="roll.name"></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <projects-site></projects-site>
  </div>
</template>

<script>
export default {
  props: [
    'TogglePopup'
  ],
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  computed: {
    projects() {
      return this.$store.state.projects;
    },
    activeProjects: function () {
      return this.$store.state.projects.filter((project) => {
        return project.path.match(this.$route.params.projName);
      });
    },
  },
}
</script>

<style lang="scss">
.project-popup {
  display: flex;
  align-items: center;
  justify-content: center;

  .project-wrapper {
    width: 100vw;
    min-height: 100vh;
    margin-top: 8vh;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-blend-mode: lighten;
    display: flex;
    align-items: center;
    flex-direction: column;
    .background-img{
      position:fixed;
      z-index: 0;
      background-size: cover;
      background-position: center;
      filter: brightness(0.5);
      width: 100vw;
      height: 100vh;
    }

    .proj-content {
      width: 80vw;
      display: flex;
      align-items: center;
      flex-direction: column;
      z-index: 100;

      .proj-head {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 4rem;
        align-items: center;

        .back-button {
          display: flex;
          justify-content: flex-start;
          position: fixed;
          cursor: pointer;
          width: 80vw;

          a {
            font-size: 1.5rem;
            display: flex;
            justify-content: center;
            width: 9rem;
            height: 2.5rem;
            padding-top: 0.5rem;
            opacity: 0.5;
          }
        }

        h1 {
          text-align: center;
          margin-top: 2rem;
          margin-bottom: 4rem;
        }
      }

      .sub-content {
        width: 100vw;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .placeholder {
          border-radius: 10px;
          background-color: black;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            position: absolute;
            z-index: 1;
          }

          iframe {
            width: 55vw/1.5;
            height: 30.9375vw/1.5;
            border-radius: 10px;
            z-index: 2;
          }
        }

        table {
          font-size: 1rem;
          color: #f5f4f3;
          margin-bottom: 5vh;
          z-index: 100;
          .roll-title {
            width: 11rem;
          }

          tr {
            vertical-align: top;
          }
        }
      }

    }
  }
}

@media only screen and (max-width: 1024px) {
  .project-wrapper{
    height:fit-content;
    padding-bottom: 5vh;
    .proj-content{
      .proj-head{
        .back-button{
          opacity: 0;
        }
      }
    }
  }
  .sub-content {
    height: fit-content;
    flex-direction: column;
    .placeholder{
    iframe {
      width: 90vw;
      height: calc(90vw * 0.5625);
    }}

    .rolls {
      margin: 4rem 1.5rem 4rem 1.5rem;
    }
  }
}
</style>